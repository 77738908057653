import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "../theme/ThemeProvider";
import { getProjectDetailApi } from "@11solutionspvtltd/eleven-solutions-trust-website";

const ProjectDetails = () => {
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(true);

  const url = process.env.REACT_APP_API_URL;
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    const fetchProjectDetails = async () => {
      if (id) {
        try {
          const response: any = await getProjectDetailApi(id, url);
          if (response) {
            setName(response.name || "");
            setDetails(response.details || "");
          }
        } catch (error) {
          console.error("Error fetching project details:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProjectDetails();
  }, [id, url]);

  return (
    <div className={`${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}>
      <div>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div
              className="flex justify-center items-center h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-600"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
            </div>
          </div>
        ) : (
          <div className="lg:mx-72 md:mx-8 mx-4 text-justify py-5">
            <h1 className="text-2xl md:text-2xl font-bold text-center text-blue-500 mb-4">
              {name}
            </h1>

            <p
              className="text-[#868EA4] mb-4 text-sm md:text-base"
              dangerouslySetInnerHTML={{ __html: details }}
            ></p>

            <div className="text-left">
              <Link to="/donate">
                <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 text-sm md:text-base">
                  Donate Now
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;

import React, { useContext } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink
import { ThemeContext } from "../theme/ThemeProvider";

function BenevityPartner() {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }

  const { isDarkMode } = themeContext;

  return (
    <div className="items-center justify-center">
      <div className={`container m-auto px-4 py-8 `}>
        <div
          className={`flex items-center flex-col lg:mx-72 p-6 text-blue-400 ${
            isDarkMode ? "bg-globalBg" : "bg-[#ffff]"
          }`}
        >
          <h1 className="text-blue-500 dark:text-blue-400 text-4xl font-bold text-center mb-8">
            Our Partnerships
          </h1>
          <NavLink to="https://benevity.com/" target="_blank" rel="benevity">
            <img src="images/Benevity.png" alt="Benevity" className="h-16" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default BenevityPartner;

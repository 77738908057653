import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const apiUrl = process.env.REACT_APP_API_URL;
 
export const fetchPurposeApi = async () => {
    const token =  cookies.get("authToken");
 
    try{
        const response = await axios.get(`${apiUrl}/Purpose/GetAllPurpose`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
    }catch (error) {
        console.error(error);
        return false;
      }
}
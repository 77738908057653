
import { useEffect, useState, useContext, useMemo, useRef } from "react";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { getDonationTypePurpose } from "../../Api/DonationTypePurpose";
import { transactionStatusApi } from "../../Api/Donation";
import { donateApi } from "../../Api/Donation";

import Cookies from "universal-cookie";
import { ThemeContext } from "../theme/ThemeProvider";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../hooks/Redux/Redux";

import { UpdateUserDetails } from "@11solutionspvtltd/eleven-solutions-common-website";
import { getFeeByUserIdApi} from "@11solutionspvtltd/eleven-solutions-trust-website";
import { fetchDonationTypeApi } from "../../Api/DonationType";
import { fetchPurposeApi } from "../../Api/DonationPurpose";
const DonationModal = () => {
  const cookies = useMemo(() => new Cookies(), []);
  const token = cookies.get("authToken");
  const location = useLocation();
  const { selectedFee } = location.state || {};

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider ");
  }
  const { isDarkMode } = themeContext;

  type Donation = {
    id: string | null;
    typeId: string;
    type: string;
    amount: number;
    purposeId: string;
    purpose: string;
    onBehalfof: string;
    onBehalfofName: string;
    feeId: string | null;
  };
  interface Option {
    id: string;
    name: string;
    label: string;
    isHiddenForUser: boolean;
  }

  interface DonationTypePurpose {
    id: string;
    donationTypeId: string;
    donationPurposeId: string;
    purposeid: string;
    label: string;
  }
  interface Fee {
    id: string;
    userId: string;
    userName: string;
    courseName: string;
    courseType: string;
    durationType: "Monthly" | "Yearly";
    amount: number;
    feeFor: "Self" | "Others";
    type: "Registration" | string;
    typeId: string;
    paid: boolean;
    donationDetailId: string | null;
  }

  const [donations, setDonations] = useState<Donation[]>([]);
  const [donationTypeOptions, setDonationTypeOptions] = useState<Option[]>([]);
  const [purposeOptions, setPurposeOptions] = useState<Option[]>([]);
  const [donationType, setDonationType] = useState<Option | null>(null) as any;
  const [donationPurpose, setDonationPurpose] = useState<Option | null>(
    null
  ) as any;
  const [donationAmount, setDonationAmount] = useState(0);
  const [onBehalfof, setOnBehalfof] = useState("Self");
  const [onBehalfofName, setOnBehalfofName] = useState("");
  const [donationTP, setDonationTP] = useState<DonationTypePurpose[]>([]);
  const [feeDetails, setFeeDetails] = useState<Fee[]>([]);
  const [selectedFeeData, setSelectedFeeData] = useState<any>();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [modal, setmodal] = useState(false);
  const userProfile = useAppSelector((state) => state.userProfile.userData);

  const [detailsMissing, setDetailsMissing] = useState(false);

  const url = process.env.REACT_APP_API_URL;

  const handleCancelClick = () => {
    setDetailsMissing(false);
  };
  const processedFeesRef = useRef(false);


  useEffect(() => {
    const fetchAllTypePurpose = async () => {
      try {
        const [typeResponse, purposeResponse, fetchDonationTypePurpose ] = await Promise.all([
          fetchDonationTypeApi(),
          fetchPurposeApi(),
          getDonationTypePurpose()
        ]);

        const FeeResponce = await getFeeByUserIdApi(url) as Fee[];
        setFeeDetails(FeeResponce);
        console.log("FeeResponce", FeeResponce);
        setDonationTypeOptions(typeResponse);
        setPurposeOptions(purposeResponse ? purposeResponse : []);
        setDonationTP(fetchDonationTypePurpose);
        setIsDataLoaded(true);
      } catch (error) {
        console.error("Error fetching taxonomies:", error);
        console.error("Failed to load donation types and purposes");
      }
    };

    fetchAllTypePurpose();
  }, []);

  useEffect(() => {
    // Reset processedFeesRef when navigating back to this page
    processedFeesRef.current = false;
  }, []); // Runs only on component mount

  useEffect(() => {
    if (
      processedFeesRef.current ||
      !selectedFee ||
      selectedFee.length === 0 ||
      donationTypeOptions.length === 0 ||
      purposeOptions.length === 0
    ) {
      console.log("Data not fully loaded or already processed. Skipping setFees.");
      return;
    }

    const normalType = donationTypeOptions.find(type => type.name === "Normal / Sadaqah Nafilah");
    const normalPurpose = purposeOptions.find(purpose => purpose.name === "Online Classes");

    if (!normalType || !normalPurpose) {
      console.error("Required donation type or purpose not found.");
      return;
    }

    const newDonations = selectedFee.map((fee) => ({
      id: null,
      feeId: fee.id,
      typeId: normalType.id,
      type: normalType.name,
      purposeId: normalPurpose.id,
      purpose: normalPurpose.name,
      amount: fee.amount,
      onBehalfof: fee.feeFor,
      onBehalfofName: fee.feeFor === "Others" ? fee.userName : "",
      courseName: fee.courseName,
      courseType: fee.courseType,
    }));

    setDonations(newDonations);
    console.log("Donation data set:", newDonations);

    processedFeesRef.current = true; // Mark as processed to prevent re-execution
  }, [selectedFee, donationTypeOptions, purposeOptions]); // Ensure it runs when these values update
  const donationOptions = donationTypeOptions.map((DonationType) => ({
    value: DonationType.name,
    id: DonationType.id,
    label: DonationType.name,
    isHiddenForUser: DonationType.isHiddenForUser
  }));

  const donationPurposeOptions = purposeOptions?.map((DonationPurpose) => ({
    value: DonationPurpose.name,
    id: DonationPurpose.id,
    label: DonationPurpose.name,
    isHiddenForUser: DonationPurpose.isHiddenForUser
  }));

  function handleResetForm() {
    setDonationType(null);
    setDonationAmount(0);
    setDonationPurpose(null);
    setOnBehalfof("Self");
    setOnBehalfofName("");
    setmodal(false);
    setSelectedFeeData(null);
  }
  function handleSubmitClick(e: React.FormEvent) {
    e.preventDefault();

    if (
      !donationAmount ||
      donationAmount <= 0 ||
      !donationPurpose ||
      !donationType
    ) {
      alert("Please fill all the fields");
      return;
    }

    if (onBehalfof === "Others" && !onBehalfofName) {
      alert("Please enter name for Others");
      return;
    }

    const donationData = {
      id: editingIndex !== null ? donations[editingIndex].id : null,
      typeId: donationType.id,
      type: donationType.value,
      purposeId: donationPurpose.id,
      purpose: donationPurpose.value,
      amount: donationAmount,
      onBehalfof: onBehalfof,
      onBehalfofName: onBehalfof === "Others" ? onBehalfofName : "",
      feeId: selectedFeeData?.id || null,
    };

    if (editingIndex !== null) {
      const updatedDonations = [...donations];
      updatedDonations[editingIndex] = donationData;
      setDonations(updatedDonations);
      setEditingIndex(null);
      setmodal(false);
    } else {
      setDonations([...donations, donationData]);
    }

    handleResetForm();
    setmodal(false);
  }
  const handleEditClick = (index: number) => {
    const donationToEdit = donations[index];

    if (!isDataLoaded) {
      console.error("Please wait while data is loading...");
      return;
    }

    const matchedType = donationTypeOptions.find(
      (option) => option.id === donationToEdit.typeId
    );

    const matchedPurpose = purposeOptions.find(
      (option) => option.id === donationToEdit.purposeId
    );

    if (!matchedType || !matchedPurpose) {
      console.error("Could not find matching type or purpose", {
        typeId: donationToEdit.typeId,
        purposeId: donationToEdit.purposeId,
        availableTypes: donationTypeOptions,
        availablePurposes: purposeOptions,
      });
      console.error("Error loading donation details");
      return;
    }

    const updates = () => {
      setEditingIndex(index);
      setmodal(true);
      setDonationType({
        value: matchedType.name,
        id: matchedType.id,
        label: matchedType.name,
      });
      setDonationPurpose({
        value: matchedPurpose.name,
        id: matchedPurpose.id,
        label: matchedPurpose.name,
      });
      setDonationAmount(donationToEdit.amount);

      setOnBehalfof(donationToEdit.onBehalfof);
      setOnBehalfofName(donationToEdit.onBehalfofName || "");
      if (donationToEdit.feeId) {
        setSelectedFeeData(feeDetails.find((fee) => fee.id === donationToEdit.feeId));
      }
    };

    updates();
    setmodal(true);
  };
  const renderEditButton = (index: number) => (
    <IconPencil
      className={` ml-2 cursor-pointer ${isDarkMode ? " text-gray-300" : "text-black"
        }`}
      onClick={() => handleEditClick(index)}
    />
  );
  const donationEzpay = async () => {
    const details = donations.map((donation) => ({
      amount: donation.amount,
      donationTypeId: donation.typeId,
      donationPurposeId: donation.purposeId,
      feeId: donation.feeId,
      onBehalfOf: donation.onBehalfofName,
    }));
    console.log(details);
    const transactionType = 1;
    const response = await donateApi(
      totalAmount,
      details,
      token,
      transactionType
    );
    window.location.href = response.data[0];
  };

  const totalAmount = donations.reduce(
    (sum, donation) => sum + Number(donation.amount || 0),
    0
  );

  useEffect(() => {
    const checkTransactionStatus = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get("donationId");

        let localId = localStorage.getItem("donationId");
        let res = await transactionStatusApi(id as string);

        if (res === "E000" && id !== localId) {
          cookies.get("donations");
          cookies.remove("donations");
          localStorage.setItem("donationId", id as string);

          const savedDonations = cookies.get("donations");
          if (savedDonations) {
            setDonations(savedDonations);
          }
        } else {
          const savedDonations = cookies.get("donations");
          if (savedDonations) {
            setDonations(savedDonations);
          }
        }
      } catch (error) {
        const savedDonations = cookies.get("donations");
        if (savedDonations) {
          setDonations(savedDonations);
        }
      }
    };
    checkTransactionStatus();
  }, [cookies]);

  const navigate = useNavigate();

  const handleAddDonationClick = () => {
    if (!token) {
      navigate("/login");
    } else {
      if (!userProfile?.name || !userProfile?.mobile || !userProfile?.address) {
        setDetailsMissing(true);
      } else {
        setmodal(true);
      }
    }
  };
  function handleSelectFee(value: string): void {
    feeDetails.forEach((fee) => {
      if (fee.id === value) {
        setSelectedFeeData(fee);
        setDonationAmount(fee.amount);
        setOnBehalfof(fee.feeFor);
        setOnBehalfofName(fee.userName);
        return;
      }
    });
  }

  return (
    <div
      className={` min-h-[70vh] ${isDarkMode ? "bg-globalBg" : "bg-[#ffff]"}`}
    >
      <div className="py-8 lg:mx-[320px] md:mx-0 ">
        <h2 className="text-center font-bold text-3xl text-blue-500 md:mb-3 ">
          Donation
        </h2>
        <p className={`px-2 ${isDarkMode ? "text-gray-500" : ""}`}>
          You can make a change by supporting our mission through your{" "}
          <b className={`${isDarkMode ? "text-white" : "text-black"}`}>
            Zakaat, Fitraat, Sadaqaat
          </b>{" "}
          and
          <b className={`${isDarkMode ? "text-white" : "text-black"}`}>
            {" "}
            Atiyat{" "}
          </b>{" "}
          in the world of digitalization.
        </p>

        {detailsMissing && (
          <>
            <UpdateUserDetails
              usermodalopen={detailsMissing}
              isDarkMode={isDarkMode}
              closeusermodal={handleCancelClick}
              url={url}
              userdetails={userProfile}
            />
          </>
        )}

        {modal && (
          <div
            className={`fixed inset-0 flex items-center justify-center ${isDarkMode
              ? "bg-gray-800 bg-opacity-90"
              : "bg-gray-500 bg-opacity-75"
              }`}
          >
            <div
              className={`p-6 rounded-lg shadow-lg w-full max-w-md border ${isDarkMode ? "bg-[#2e2e2e] border-gray-700 " : "bg-[#ffff]"
                }`}
            >
              <div className="relative">
                <div className="absolute -top-10 right-0">
                  <button
                    onClick={() => {
                      setmodal(false)
                      handleResetForm();
                    }}
                    className={`text-3xl   ${isDarkMode
                      ? "text-gray-200 hover:text-gray-300"
                      : " text-gray-600  hover:text-gray-800"
                      }`}
                  >
                    &times;
                  </button>
                </div>
                <div className="text-center mb-8">
                  <div className="flex justify-center mt-6">
                    <img src="images/TFLogo.svg" alt="Logo" className="w-16" />
                  </div>
                  <span className="text-transparent bg-clip-text font-extrabold text-[18px] ml-2.5 bg-gradient-to-r from-[#FD6E6A] to-[#FFC600]">
                    TajushShariah Foundation
                  </span>
                </div>
              </div>
              <label
                className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                  } `}
              >
                Donation Type*
                <select
                  required
                  className={`block w-full p-2 mt-1 border rounded  font-normal text-sm focus:outline-none ${isDarkMode
                    ? "border-gray-600 bg-gray-800 text-white  "
                    : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                    }`}
                  value={donationType?.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const selectedType = donationOptions.find(
                      (option) => option.value === value);
                    if (selectedType) {
                      setDonationType(selectedType);
                      setDonationPurpose(null);
                    }
                  }}
                >
                  <option value="" disabled selected>
                    Select your option
                  </option>
                  {donationOptions
                    .map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
              </label>

              <label
                className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                  } `}
              >
                Donation Purpose*
                <select
                  required
                  className={`block w-full p-2 mt-1 rounded font-normal text-sm focus:outline-none border ${isDarkMode
                    ? "border-gray-600 bg-gray-800 text-white"
                    : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                    }`}
                  value={donationPurpose?.value || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    const selectedDTP = donationTP.filter((dtp) => dtp.donationTypeId === donationType?.id).find((dtp) => {
                      const taxonomyPurpose = donationPurposeOptions.find((p) => p.id === dtp.donationPurposeId);
                      return taxonomyPurpose?.value === value;
                    });
                    if (selectedDTP) {
                      const taxonomyPurpose = donationPurposeOptions.find((p) => p.id === selectedDTP.donationPurposeId);
                      if (taxonomyPurpose) {
                        setDonationPurpose({
                          value: taxonomyPurpose.value,
                          id: selectedDTP.donationPurposeId,
                          label: taxonomyPurpose.value
                        });
                      }
                    }
                  }}
                >
                  <option value="" className="text-gray-400" disabled selected>
                    Select Donation Purpose
                  </option>
                  {donationTP
                    .filter((dtp: any) => dtp.donationTypeId === donationType?.id).map((donationtp: any) => {
                      const taxonomyPurpose = donationPurposeOptions.find(
                        (purpose) => purpose.id === donationtp.donationPurposeId
                      );

                      return {
                        value: taxonomyPurpose.value,
                        id: donationtp.donationPurposeId,
                        label: taxonomyPurpose.value,
                        isHiddenForUser: taxonomyPurpose.isHiddenForUser,
                      };
                    })
                    .filter((item: any): item is { value: string; id: string; label: string; isHiddenForUser: boolean; } =>
                      item !== undefined && item.isHiddenForUser === false
                    )
                    .map((item: any) =>
                      <option key={item.id} value={item.value}>
                        {item.label}
                      </option>
                    )}
                </select>
              </label>

              {donationType?.value === "Normal / Sadaqah Nafilah" &&
                donationPurpose?.value === "Online Classes" && feeDetails.length > 0 && (
                  <label
                    className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                      } `}
                  >
                    Select Course
                    <select
                      required
                      className={`block w-full p-2 mt-1 border rounded font-normal text-sm focus:outline-none ${isDarkMode
                        ? "border-gray-600 bg-gray-800 text-white"
                        : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                        }`}
                      value={selectedFeeData?.id}
                      onChange={(e) => handleSelectFee(e.target.value)}
                    >
                      <option value="" disabled selected>
                        Select Course
                      </option>
                      {feeDetails
                        .filter((fee) => !donations.some((donation) => donation.feeId === fee.id) || fee.id === selectedFeeData?.id)
                        .map((fee) => (
                          <option key={fee.id} value={fee.id}>
                            {fee.courseName} - {fee.courseType} - {fee.userName}
                          </option>
                        ))}
                    </select>
                  </label>
                )}

              <div>
                <label
                  className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                    } `}
                >
                  Donation Amount*
                </label>
                <input
                  disabled={selectedFeeData}
                  type="text"
                  className={`w-full p-2 border rounded-md text-sm font-normal focus:outline-none
                                     ${isDarkMode
                      ? "border-gray-600 bg-gray-800 text-white  "
                      : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                    }`}
                  value={donationAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    const amounts = value
                      .split(",")
                      .map((amount) => Number(amount.trim()))
                      .filter((amount) => !isNaN(amount));
                    setDonationAmount(Number(amounts));
                  }}
                />
              </div>


              <label
                className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                  } `}
              >
                On Behalf of*
                <select
                  required
                  className={`block w-full p-2 mt-1 border rounded font-normal text-sm focus:outline-none ${isDarkMode
                    ? "border-gray-600 bg-gray-800 text-white"
                    : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                    }`}
                  value={onBehalfof}
                  onChange={(e) => setOnBehalfof(e.target.value)}
                >
                  <option value="Self">Self</option>
                  <option value="Others">Others</option>
                </select>
              </label>

              {onBehalfof === "Others" && (
                <label
                  className={`block text-sm mt-2 font-semibold ${isDarkMode ? "text-white" : "text-black"
                    } `}
                >
                  Name*
                  <input
                    type="text"
                    className={`block w-full p-2 mt-1 border rounded focus:outline-none font-normal text-sm ${isDarkMode
                      ? "border-gray-600 bg-gray-800 text-white  "
                      : "border-gray-300 bg-white text-black focus:bg-gray-100 focus:border-blue-500"
                      }`}
                    value={onBehalfofName}
                    onChange={(e) => setOnBehalfofName(e.target.value)}
                  />
                </label>
              )}
              <button
                className=" text-left mt-4 px-3 py-2 text-sm font-semibold bg-blue-500 text-white rounded hover:bg-blue-600"
                onClick={handleSubmitClick}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        {donations.length !== 0 && (
          <div className="mb-5 mt-6">
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse ">
                <thead>
                  <tr
                    className={`border-b  text-left  ${isDarkMode
                      ? " text-gray-300 border-gray-700"
                      : "text-black border-gray-300"
                      }`}
                  >
                    <th className="px-4 py-1 text-sm">Actions</th>
                    <th className="px-4 py-2 text-sm">Donation Type</th>
                    <th className="px-4 py-2 text-sm">Amount</th>
                    <th className="px-4 py-2 text-sm">Purpose</th>
                    <th className="px-4 py-2 text-sm">On Behalfof</th>
                    <th className="px-4 py-2 text-sm">On Behalfof Name</th>
                  </tr>
                </thead>
                <tbody>
                  {donations.map((donation, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 flex flex-row">
                        <button
                          className="mr-2 text-red-600 hover:text-red-800"
                          onClick={() => {
                            const newDonations = [...donations];
                            newDonations.splice(index, 1);
                            setDonations(newDonations);
                          }}
                        >
                          <IconTrash />
                        </button>
                        {renderEditButton(index)}
                      </td>
                      <td
                        className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"
                          }`}
                      >
                        {donation.type}
                      </td>
                      <td
                        className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"
                          }`}
                      >
                        {donation.amount}
                      </td>
                      <td
                        className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"
                          }`}
                      >
                        {donation.purpose}
                      </td>
                      <td
                        className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"
                          }`}
                      >
                        {donation.onBehalfof}
                      </td>
                      <td
                        className={`px-4 py-2 text-sm font-normal  ${isDarkMode ? " text-gray-300" : "text-black"
                          }`}
                      >
                        {donation.onBehalfofName}
                      </td>
                    </tr>
                  ))}
                  <tr
                    className={`${isDarkMode
                      ? " text-gray-300 border-t border-gray-700"
                      : "text-black border-t border-gray-300"
                      }`}
                  >
                    <td className="px-4 py-2"></td>
                    <td className="px-4 py-2 text-sm">Total</td>
                    <td className="px-4 py-2 text-sm">{totalAmount}</td>
                    <td className="px-4 py-2"></td>
                    <td className="px-4 py-2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        <div className="flex flex-wrap items-center gap-4 mt-8 px-2">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm font-semibold "
            onClick={handleAddDonationClick}
          >
            Add {donations.length > 0 ? "More" : "Donation"}
          </button>
          {donations.length > 0 && (
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded text-sm font-semibold  hover:bg-blue-600"
              onClick={donationEzpay}
            >
              Donate Now
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default DonationModal;

import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../theme/ThemeProvider";
import { NavLink } from "react-router-dom";
import Scrolltopbutton from "../scrolltopbutton/Scrolltopbutton";
import { getProjectDescApi } from "@11solutionspvtltd/eleven-solutions-trust-website";

const HeaderProjects = () => {
  const themeContext = useContext(ThemeContext);
  if (!themeContext) {
    throw new Error("ThemeContext must be used within a ThemeProvider");
  }
  const { isDarkMode } = themeContext;

  const url = process.env.REACT_APP_API_URL;
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const response = await getProjectDescApi(url);
        const sortedProjects = (response as any[]).sort(
          (a, b) => a.sequence - b.sequence
        );
        setProjects(sortedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjectsData();
  }, [url]);

  return (
    <div className={` ${isDarkMode ? "bg-globalBg" : "bg-white"}`}>
      <div className="py-8">
        <h2 className="text-4xl font-bold text-center text-blue-500 dark:text-blue-400 mb-8">
          Our Projects
        </h2>

        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <div
              className="flex justify-center items-center h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue-600"
              role="status"
            ></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 lg:mx-72 md:px-3 px-2">
            {projects.length > 0 ? (
              projects.map((article) => {
                const projectBudget = article.budget;
                const projectAdjustment = article.adjustment;
                const percentage =
                  projectBudget > 0
                    ? Math.min((projectAdjustment / projectBudget) * 100, 100)
                    : 0;
                const remaining = projectBudget - projectAdjustment;

                return (
                  <NavLink
                    to="/ProjectDetails"
                    state={{ id: article.id }}
                    key={article.sequence}
                  >
                    <div
                      className={`rounded-md overflow-hidden shadow-md hover:scale-105 transition-transform duration-150 ${
                        isDarkMode ? " bg-[#2e2e2e]" : ""
                      }`}
                    >
                      <div className="relative flex justify-center items-center">
                        <img
                          src={`${article.image || "Images/NoImage.jpg"}`}
                          alt={article.name}
                          className="w-40 mt-3 h-auto object-contain"
                        />
                        {article.status && (
                          <span className="absolute top-2 right-3 bg-gradient-to-r from-yellow-400 to-red-500 text-white text-xs font-medium py-0.5 px-3 rounded-3xl">
                            {article.status}
                          </span>
                        )}
                      </div>
                      <div className="p-4">
                        <h3
                          className={`text-base text-center font-semibold mb-4 ${
                            isDarkMode ? "text-[#afaea9]" : ""
                          }`}
                        >
                          {article.name}
                        </h3>
                        <p
                          className="text-sm leading-relaxed mt-4 font-light text-gray-400"
                          dangerouslySetInnerHTML={{
                            __html: article.description,
                          }}
                        ></p>

                        <hr className="mt-6 ml-0" />
                        <div className="space-y-2 mt-2">
                          <div className="flex justify-between items-center w-full text-sm font-medium text-gray-600">
                            <p className="flex flex-col flex-start">
                              <span className="text-gray-500">Budget </span>{" "}
                              <span className="font-semibold text-black text-lg">
                                ₹{projectBudget.toLocaleString()}
                              </span>
                            </p>
                            <p className=" flex flex-col flex-end">
                              {" "}
                              <span className="text-gray-500">
                                Remaining
                              </span>{" "}
                              <span className="font-semibold text-black text-lg">
                                ₹{remaining.toLocaleString()}
                              </span>
                            </p>
                          </div>

                          <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
                              style={{ width: `${percentage}%` }}
                            ></div>
                          </div>
                        </div>

                        <div className="mt-4 flex flex-col justify-between gap-4">
                          <NavLink
                            to="/ProjectDetails"
                            state={{ id: article.id }}
                            className="block text-center w-full bg-gray-700 text-white py-2 rounded hover:bg-gray-800"
                          >
                            Show More
                          </NavLink>
                          <NavLink
                            to="/donate"
                            className="block text-center w-full bg-blue-400 text-white py-2 rounded hover:bg-blue-500"
                          >
                            Donate
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              })
            ) : (
              <p className="text-center text-gray-500">No projects available</p>
            )}
          </div>
        )}
      </div>
      <Scrolltopbutton />
    </div>
  );
};

export default HeaderProjects;
